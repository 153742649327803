.manage-content-container {
  .manage-content-block {
    flex: 1;
    padding: 20px 30px;
    min-height: calc(100vh - 60px);
    overflow: auto;
    background-color: white;
    // margin: 0 10px 0 20px;
    // background-color: #ffffff;

    .content {
      margin-bottom: 40px;

      .sub-title {
        margin-top: 40px;
        text-transform: uppercase;
        color: #2a3236;
        font-family: 'Arial-BoldMT';
        font-size: 13px;
      }
    }

    .content-block-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 21px;
      // position: sticky;
      // top: 0;
      // position: -webkit-sticky;
      // z-index: 10;
      .title {
        display: flex;
        align-items: center;
        font-family: 'Arial-BoldMT';
        text-transform: uppercase;
        font-size: 16px;
        color: #2a3236;
      }

      .title-right-button {
        button {
          margin-right: 0;
        }
      }
    }
  }
}

.snackbar-content {
  display: flex;
  flex: 1;
  padding: 0px 10px;
  max-width: 720px;
  font-size: 13px;
  border: solid 1px #63b266;
  align-content: center;
}
