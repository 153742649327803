@import 'reset-form';

html#manage-html,
#yojeeManage-container {
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial;
  background-color: #f5f5f5;

  .manage-MuiInputLabel-outlined {
    pointer-events: initial;
  }

  .flex-grow {
    flex-grow: 1;
  }

  .flex {
    display: flex;
  }

  .layout {
    flex-grow: 1;
    .top-section {
      height: 60px;
      width: 100%;
      position: fixed;
      top: 0;
    }

    .bottom-section {
      height: 100%;
      width: 100%;
      margin-top: 60px;
    }
  }

  .loading-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .message-snack-bar {
    top: 15px;
    right: 18px;
    width: auto;
    // width: 720px;
    height: 33px;
  }
  input[type='checkbox'] {
    width: 20px;
    height: 20px;
  }

  .table-row {
    &:hover {
      background-color: #f0f8fb;
      cursor: pointer;
      .action-utilities {
        visibility: visible;
      }
    }
  }
  .action-utilities {
    visibility: hidden;
  }
  .input-label {
    margin-bottom: 0.3em;
    font-size: 0.8em;
    color: #5e737d;
    font-family: ArialMT;
    letter-spacing: normal;

    .input-label-info {
      width: 15px;
      height: 15px;
      margin-left: 8px;
    }
  }
  .custom-radio {
    input[type='checkbox'],
    input[type='radio'] {
      width: 100%;
      height: 100%;
    }
  }

  .action-button {
    color: #2f7ae2;
    text-transform: none;
    float: right;
    margin-top: 0.6em;
    &:hover {
      cursor: pointer;
      color: darkblue;
    }
  }

  .btn {
    height: 26px;
    border-radius: 3px;
    background-color: #2f7ae2;
    padding: 0 12px !important;
    box-shadow: none !important;
    margin-left: 10px;

    .btn-label {
      font-family: Arial;
      font-size: 12px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
    }

    &.disabled {
      background-color: rgba(0, 0, 0, 0.12) !important;
    }

    &:first-child {
      margin-left: 0;
    }

    &.btn-danger {
      background-color: #f50057;
    }
  }

  .search-bar-container {
    position: relative;

    .search-input-container {
      &.form-group {
        label {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .label-link {
            font-size: 10px;
            color: #2f7ae2;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .page-actions-menu {
    & > li {
      color: #5e737d;
      font-size: 13px;
      font-family: Arial-BoldMT;
      font-weight: bold;
      padding-top: 3.5px;
      font-stretch: normal;
      border-radius: 4px;
      margin-bottom: 10px;
      letter-spacing: normal;
      padding-bottom: 3.5px;
      text-transform: uppercase;
    }
  }

  .content-iframe {
    width: 100%;
    height: calc(100vh - 120px);
    border: 0;
    overflow: auto;

    &.no-bottom-space {
      height: calc(100vh - 23px);
    }
  }
}

.opacity-50 {
  opacity: 0.5;
}
